// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 900px;


// Small devices
@mixin sm {
  @media only screen and (max-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media only screen and (max-width: #{$screen-md-min}) {
      @content;
  }
}