@import "./breakpoints";

$d: 30;      // density
$w: 1300;     // grid size
$s: 280s;       // speed

// Generate the snow image using radial gradients
$grad: ();
@for $i from 0 to $d {
  $v: random(3);
  $a: random(5) * .1 + .5;
  $grad: $grad, radial-gradient(
      $v+px $v+px at
      (random($w - $v * 2) + $v)+px
      (random($w - $v * 2) + $v)+px, 
      rgba(200,200,255,$a) 50%,
      rgba(0,0,0,0)
  ) !global;
}

// The CSS
html {
  height: 100%;
}


.snow, .snow:before, .snow:after {
  position: fixed;
  top: -$w + px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: $grad;
  background-size: $w + px $w + px;
  animation: snow $s linear infinite;
  content: "";
  z-index: -2;

  @include sm {
    display: none;
  }
}
.snow:after {
  margin-left: -$w/3 + px;
  opacity: .4;
  animation-duration: $s*2;
  animation-direction: reverse;
  filter: blur(3px);
}
.snow:before {
  animation-duration: $s*3;
  animation-direction: reverse;
  margin-left: -$w/2 + px;
  opacity: .65;
  filter: blur(1.5px);
}
@keyframes snow {
  to {
    transform: translateY($w + px);
  }
}
